<template>
  <div class="agency-user-detail">
    <div class="card">
      <!-- ========================= Header Section ======================== -->
      <div class="row px-2 py-2 header-section">
        <!-- Edit & Close Button -->
        <div class="col-12 d-flex justify-content-between">
          <button
            v-if="canEdit"
            class="btn main"
            @click="toEditPage(detail.id)"
          >
            <i class="fas fa-pen"></i> <span class="ml-1">Edit</span>
          </button>
          <button class="btn flat circle" @click="$emit('close')">
            <i class="flaticon-close"></i>
          </button>
        </div>
      </div>
      <!-- Detail content -->
      <div class="content p-4">
        <!-- Avatar -->
        <div class="row mb-2">
          <div
            class="col-12 md-col-4 d-flex justify-content-center md-justify-content-end"
          >
            <img
              v-if="detail.avatar.length > 0"
              :src="$getFileURL(detail.avatar[0])"
              alt="Avatar"
              class="avatar-img"
              @error="$setAltPhoto"
            />
            <div v-else class="avatar-placeholder">
              <i class="fas fa-user-circle"></i>
            </div>
          </div>
          <!-- Info -->
          <div class="main-info col-12 md-col-8 px-2 mt-2">
            <div class="col-12">
              <p
                class="title d-flex justify-content-center align-items-center md-justify-content-start"
              >
                {{ detail.name }}
                <span v-if="detail.isCertified" class="d-inline-block">
                  <div class="certified ml-1"><i class="fas fa-check"></i></div>
                </span>
              </p>
            </div>
            <!-- Status -->
            <div class="col-12 text-center md-text-left">
              <div class="status" :class="getStatusClass(detail.isActive)">
                {{ detail.isActive ? "Active" : "Inactive" }}
              </div>
            </div>
          </div>
        </div>
        <!-- Agency Details -->
        <fd-form-section title="Agency Details">
          <div :class="detailCSS">
            <p>Agency</p>
            <p class="data">{{ detail.agency.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>Branch</p>
            <p class="data">{{ detail.agencyBranch.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>Role</p>
            <p class="data">{{ detail.rolePermission.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>REN/E/PEA License</p>
            <p class="data">{{ detail.renLicense || "-" }}</p>
          </div>
        </fd-form-section>
        <!-- Personal Details -->
        <fd-form-section title="Personal Details">
          <div :class="detailCSS">
            <p>Actual Name</p>
            <p class="data">{{ detail.actualName }}</p>
          </div>
          <div :class="detailCSS">
            <p>Birthdate</p>
            <p class="data">
              {{ $moment(detail.user.birthdate).format("LL") }}
            </p>
          </div>
          <div :class="detailCSS">
            <p>Gender</p>
            <p class="data">{{ detail.user.gender }}</p>
          </div>
          <div class="col-12 px-1 mb-2">
            <p>Introduction</p>
            <p class="data">
              {{ detail.introduction ? detail.introduction : "None" }}
            </p>
          </div>
          <div class="col-12 px-1 mb-2">
            <p>Experience</p>
            <p class="data">
              {{ detail.experience ? detail.experience : "None" }}
            </p>
          </div>
        </fd-form-section>
        <!-- Contact Details -->
        <fd-form-section title="Contact Details">
          <div :class="detailCSS">
            <p>Contact</p>
            <p class="data">{{ $getUserPhoneNumber(detail.user) || "-" }}</p>
          </div>
          <div :class="detailCSS">
            <p>Email</p>
            <p class="data">{{ detail.email }}</p>
          </div>
        </fd-form-section>
        <!-- Bank Details -->
        <fd-form-section title="Bank Details">
          <div :class="detailCSS">
            <p>Bank</p>
            <p class="data">{{ detail.bank }}</p>
          </div>
          <div :class="detailCSS">
            <p>Account Number</p>
            <p class="data">{{ detail.bankAccountNumber }}</p>
          </div>
          <div :class="detailCSS">
            <p>Account Full Name</p>
            <p class="data">{{ detail.bankAccountFullName }}</p>
          </div>
        </fd-form-section>
        <!-- Address Details -->
        <fd-form-section title="Address Details">
          <div class="col-12 px-1 mb-2">
            <p>Address</p>
            <p class="data">{{ detail.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>Area</p>
            <p class="data">{{ detail.area.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>City</p>
            <p class="data">{{ detail.city.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>State</p>
            <p class="data">{{ detail.state.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>Country</p>
            <p class="data">{{ detail.country.name }}</p>
          </div>
        </fd-form-section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      detailCSS: "col-12 sm-col-6 md-col-3 mb-2 px-1",
      canEdit: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getAccessPermission();
  },
  methods: {
    async getAccessPermission() {
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "agencyUser.update"
      );
    },
    toEditPage(id) {
      this.$router.push({ name: "ManageAgencyUsersEdit", params: { id: id } });
    },
    getStatusClass(status) {
      switch (status) {
        case true:
          return "active";
        case false:
          return "inactive";

        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.agency-user-detail {
  .header-section {
    box-shadow: 0px 4px 10px -4px #00000015;
  }
  .content {
    max-height: calc(100vh - 150px);
    overflow-y: auto;

    .avatar-placeholder {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: #ddd;
      text-align: center;
      i {
        font-size: 80px;
        line-height: 1.25;
        color: white;
      }
    }
    .avatar-img {
      @include image(cover);
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .main-info {
      .title {
        font-size: 24px;
      }
    }
    .certified {
      background: $color-info;
      color: white;
      border-radius: 1000px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 12px;
      }
    }
    .label {
      font-size: 14px;
    }
    .data {
      font-size: 16px;
    }
  }
}
</style>
